






















import { useUploader } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FileCreateResponseDto } from "@/models/interface/storage";
import { Component, Mixins, Prop } from "vue-property-decorator";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

@Component
export default class ImageUploader extends Mixins(MNotificationVue) {
  imageUrl = "";
  loading = false;

  @Prop({ type: String, default: "", required: true })
  module!: string;

  remove(): void {
    this.imageUrl = "";
    this.emits(null);
  }

  emits(response: FileCreateResponseDto | null): void {
    this.$emit("onSuccess", response);
  }

  async upload(file: File): Promise<void> {
    const { uploadImage } = useUploader();
    try {
      this.loading = true;
      const formData = new FormData();
      formData.set("file", file);

      const res = await uploadImage(formData, this.module);
      this.emits(res);
      this.imageUrl = (await getBase64(file)) as string;

      this.showNotifSuccess("notif_file_upload_successfully", {
        filename: file.name,
      });
    } catch (error) {
      this.showNotifError("notif_file_upload_failed", { filename: file.name });
    } finally {
      this.loading = false;
    }
  }
}
